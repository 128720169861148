import { List, Typography } from "@mui/material";
import AdminManagementListItem from "./AdminManagementListItem";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  AdminManagementDialogKey,
  Kurs,
  KursBelegung,
  Mitglied,
  Standort,
  Warteliste,
  WartelistenKundenData,
} from "../../models/admin-management-entities";
import AdminManagementListHeader from "./AdminManagementListHeader";
import { ReactNode, useState } from "react";
import AdminInfoDialog from "../AdminTrainerApp/Dialogs/AdminInfoDialog";
import trashcan from "../../img/trashcanSVG.svg";
import { TrainerInterface } from "../../models/trainer";

type DataTypes =
  | Mitglied[]
  | Standort[]
  | Warteliste[]
  | Kurs[]
  | KursBelegung[]
  | WartelistenKundenData[]
  | TrainerInterface[];

export interface ContextMenuItem {
  text: string;
  icon: string;
  onClick: () => void;
}

export const AdminManagmentList: React.FC<{
  dialogKey: AdminManagementDialogKey;
  data: DataTypes;
  columns?: { title: string; flex?: number; index?: number }[];
  listItemColumns: {
    attrName: string;
    key?: number;
    flex?: number;
    processFnct?: (_: any) => string;
  }[];
  listItemOnClick: (id: number) => void;
  noDataText: string;
  isLoading?: boolean;
  loadMoreDataFct?: () => Promise<void>;
  deleteDialogTextContent?: ReactNode;
  currentItem?: Mitglied | Standort | Kurs | TrainerInterface;
  deleteFnct?: Function;
  setIsEntityDialogOpen?: (_: boolean) => void;
  setDialogEntityData?: (_: any) => void;
  contextMenuItems?: ContextMenuItem[];
}> = ({
  dialogKey,
  isLoading,
  data,
  loadMoreDataFct,
  columns,
  listItemColumns,
  listItemOnClick,
  noDataText,
  deleteDialogTextContent,
  currentItem,
  deleteFnct,
  setIsEntityDialogOpen,
  setDialogEntityData,
  contextMenuItems,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  return (
    <>
      {columns && (
        <AdminManagementListHeader
          columns={columns}
        ></AdminManagementListHeader>
      )}
      <List
        sx={{
          width: "100%",
          margin: "auto",
          padding: "0",
        }}
      >
        <InfiniteScroll
          scrollThreshold={0.95}
          dataLength={data ? data.length : 0}
          next={loadMoreDataFct}
          hasMore={true}
          loader={<></>}
        >
          {data &&
            data.map(
              (
                item:
                  | Mitglied
                  | Standort
                  | Warteliste
                  | Kurs
                  | Mitglied
                  | KursBelegung
                  | WartelistenKundenData
                  | TrainerInterface,
                idx
              ) => (
                <AdminManagementListItem
                  dialogKey={dialogKey}
                  key={idx}
                  data={item}
                  columns={listItemColumns}
                  onClickFct={listItemOnClick}
                  showBelegungenContextMenu={true}
                  setIsDeleteDialogOpen={setIsDeleteDialogOpen}
                  setIsEntityDialogOpen={setIsEntityDialogOpen}
                  contextMenuItems={contextMenuItems}
                ></AdminManagementListItem>
              )
            )}
        </InfiniteScroll>
      </List>
      {data && data.length === 0 && !isLoading && (
        <Typography
          sx={{
            marginTop: "50px",
            textAlign: "center",
            fontSize: "var(--h1)",
            color: "var(--grey)",
          }}
        >
          {noDataText}
        </Typography>
      )}
      {/* Delete Dialog */}
      {isDeleteDialogOpen && (
        <AdminInfoDialog
          isOpen={isDeleteDialogOpen}
          onCloseDialog={() => {
            setDialogEntityData && setDialogEntityData(undefined);
            setIsDeleteDialogOpen(false);
          }}
          iconURL={trashcan}
          textChildren={deleteDialogTextContent}
          rightBtnLabel="Bestätigen"
          rightBtnOnClick={async () => {
            await deleteFnct(currentItem.ID);
            setIsDeleteDialogOpen(false);
            setDialogEntityData && setDialogEntityData(undefined);
          }}
          leftBtnLabel="Abbrechen"
          leftBtnOnClick={() => {
            setDialogEntityData && setDialogEntityData(undefined);
            setIsDeleteDialogOpen(false);
          }}
        ></AdminInfoDialog>
      )}
    </>
  );
};
