import * as React from "react";
import { Box, Typography, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/de";

dayjs.locale("de");

export const AdminManagementDateInputField: React.FC<{
  label: string;
  value: string;
  onChange: (dateString: string) => void;
  initialData?: any;
}> = ({ label, value, onChange, initialData }) => {
  const [borderColor, setBorderColor] = useState("2px solid var(--green)");
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    dayjs(value, "DD.MM.YYYY") || null
  );

  useEffect(() => {
    setBorderColor("2px solid var(--green)");
  }, [initialData]);

  const handleDateChange = (value: Dayjs | null) => {
    if (value) {
      if (
        (!selectedDate || !value.isSame(selectedDate, "day")) &&
        !(value.isBefore(dayjs(), "day") || value.isSame(dayjs(), "day"))
      ) {
        setBorderColor("2px solid var(--red)");
        setSelectedDate(value);
        return;
      }
      setBorderColor("2px solid var(--orange)");
      setSelectedDate(value);
      onChange(value.format("DD.MM.YYYY"));
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
      <Box
        sx={{
          border: borderColor,
          borderRadius: "var(--borderRadiusLarge)",
          padding: "10px",
        }}
      >
        <Typography
          sx={{
            fontSize: "var(--h3)",
            color: "var(--grey)",
            paddingBottom: "10px",
          }}
        >
          {label}
        </Typography>
        <DatePicker
          disableFuture={true}
          inputFormat="DD.MM.YYYY"
          value={selectedDate}
          onChange={handleDateChange}
          maxDate={dayjs()}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: "98%",
                height: "20px",
                margin: "0px",
                padding: "0px",
                "& .MuiInputBase-root": {
                  border: "none",
                  padding: 0,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiOutlinedInput-input": {
                  padding: 0,
                },
              }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
};
