import axios from "axios";
import { localStorageKeys } from "../constants/storage-keys";
import { logoutUser, refreshAdminToken } from "../api/auth-api";
import { AUTH_API_URL } from "../config/config";

const axiosAuthInstance = axios.create({
  baseURL: AUTH_API_URL,
});

const refreshEndpointPath = "/auth/refresh-admin";

let isRefreshing = false;
let refreshSubscribers: any[] = [];

function addSubscriber(callback: any) {
  refreshSubscribers.push(callback);
}

function onTokenRefreshed(newToken: string) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
}

axiosAuthInstance.interceptors.request.use(
  (config) => {
    const requestURL = config.url;

    if (!requestURL.includes(refreshEndpointPath)) {
      const authToken = localStorage.getItem(localStorageKeys.adminAccessToken);
      config.headers["Authorization"] = `Bearer ${authToken}`;
    }

    if (["POST", "PUT", "DELETE"].includes(config.method?.toUpperCase())) {
      const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);
      if (trainerUUID) {
        config.headers["X-Trainer-UUID"] = trainerUUID;
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuthInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (
      (error.response.status === 401 || error.response.status === 403) &&
      error.config.url !== refreshEndpointPath
    ) {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addSubscriber((newToken: string) => {
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            resolve(axios(originalRequest));
          });
        });
      }
      isRefreshing = true;
      originalRequest._retry = true;

      try {
        const tokensResponse = await refreshAdminToken();
        const accessToken = tokensResponse.data.accessToken;

        onTokenRefreshed(accessToken);

        originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

        return axios(originalRequest);
      } catch (refreshError) {
        await logoutUser();
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosAuthInstance;
