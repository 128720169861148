import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";

import { useContext, useEffect, useState } from "react";

import { HttpStatusCode } from "axios";
import { getDate } from "../../../util/DateFormatHelper";
import { sendEmail } from "../../../api/email-api";
import { Course, CourseStates } from "../../../models/course";
import TextFieldWithChars from "../../TextFieldWithChars";
import { localStorageKeys } from "../../../constants/storage-keys";
import { SnackBarContext } from "../../../context/snackbar-context";
import { Trainer } from "../../../models/trainer";
import {
  getParticipantsByCourseName,
  updateCourse,
} from "../../../api/admin-trainer-app-api";
import { getTrainerEntity } from "../../../api/trainer-api";
import { formatSnackBarErrMsg } from "../../../util/SnackBarErrorMsgFormatter";
import { Participant } from "../../../models/participant";
import HeaderCourseDocu from "../InfoCard/HeaderCourseDocu";
import AdminInfoDialog from "./AdminInfoDialog";

const SelectTextfield: React.FC<{
  values: string[];
  label: string;
  onChange: (value: string) => void;
  error?: boolean;
}> = ({ values, label, onChange, error }) => {
  const [selectedValue, setSelectedValue] = useState("");

  /* eslint-disable */
  useEffect(() => {
    onChange(selectedValue);
  }, [selectedValue]);
  /* eslint-enable */

  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedValue(e.target.value);
  };

  return (
    <FormControl error={error} fullWidth sx={{ marginTop: "20px" }}>
      <InputLabel>{label}</InputLabel>
      <Select
        required
        value={selectedValue}
        label={label}
        onChange={handleChange}
      >
        {values.map((value, index) => (
          <MenuItem key={index} value={value}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const AdminNotifyAttendeesDialog: React.FC<{
  isOpen: boolean;
  course: Course;
  onCloseDialog: () => void;
}> = ({ isOpen, course, onCloseDialog }) => {
  const MIN_CHARS_TEXTFIELD = 20;
  const MAX_CHARS_TEXTFIELD = 400;

  const MAX_CHARS_SUBJECT = 40;

  const trainerUUID = localStorage.getItem(localStorageKeys.trainerUUID);
  const snackBarCtx = useContext(SnackBarContext);
  const [trainer, setTrainer] = useState<Trainer>({} as Trainer);

  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isSubjectValid, setIsSubjectValid] = useState(true);
  const [message, setMessage] = useState("");
  const [isMessageValid, setIsMessageValid] = useState(true);
  const [isInputValid, setIsInputValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    getTrainerEntity(trainerUUID).then(
      ({ status, trainer }: { status: number; trainer: Trainer }) => {
        if (status !== HttpStatusCode.Ok) {
          snackBarCtx.openSnackbar(
            formatSnackBarErrMsg(
              status,
              `Beim Laden des TrainerObjektes ist ein Fehler aufgetreten. Bitte lade die Seite neu.`
            )
          );
          return;
        }
        setTrainer(trainer);
      }
    );
  }, []);

  useEffect(() => {
    setIsInputValid(checkIsInputValid());
  }, [subject, title, message]);
  /* eslint-enable */

  const checkTitleValid = () => {
    return title.length !== 0;
  };

  const checkSubjectValid = () => {
    return subject.length !== 0;
  };

  const checkMsgValid = () => {
    return message.length >= MIN_CHARS_TEXTFIELD;
  };

  const checkIsInputValid = () => {
    return checkTitleValid() && checkSubjectValid() && checkMsgValid();
  };

  async function handleSendBtn() {
    const sender = `${trainer.firstName} ${trainer.lastName} - Sportfreunde 06 e.V`;

    const mailTitle = `${title} - ${course.courseName} ${getDate(
      course.startDate
    )}: ${subject}`;

    const mailText = `<p>${message}</p><p>-</p>
    <p>${trainer.firstName} ${trainer.lastName}</p>
      <p> Sportfreunde Berlin 06 e.V. </p>
      <p> Spiekermannstraße 31 A </p>
      <p> 13189 Berlin </p>
      <hr/> <strong>Bitte nicht auf diese E-Mail antworten. Die Antworten werden nicht gelesen. </strong>`;

    const participantsMails = (
      await getParticipantsByCourseName(course.courseName)
    ).map((p: Participant) => p.email);

    await sendEmail(
      sender,
      "mitglieder@sportfreunde-berlin.de",
      participantsMails,
      mailTitle,
      mailText
    ).then((response) => {
      if (response.success === true) {
        onCloseDialog();
        snackBarCtx.openSnackbar(
          "Deine Nachricht wurde erfolgreich an alle Teilnehmenden gesendet",
          "success"
        );
      } else {
        snackBarCtx.openSnackbar(
          `Deine Nachricht konnte nicht gesendet werden. ${response.message}`,
          "error"
        );
      }
    });

    let updateAttr: Record<string, any>;
    if (title === "Nachricht") {
      updateAttr = {
        message: message,
      };
    }
    if (title === "Absage") {
      updateAttr = {
        message: message,
        documentingTrainerUUID: trainer.uuid,
        state: CourseStates.CANCELED,
      };
    }
    await updateCourse(course.courseID, updateAttr);
  }

  return (
    <>
      <AdminInfoDialog
        isOpen={isOpen}
        onCloseDialog={() => {
          onCloseDialog();
        }}
        leftBtnLabel="Abbrechen"
        leftBtnOnClick={() => onCloseDialog()}
        rightBtnLabel="Absenden"
        rightBtnOnClick={async () => {
          setIsSubmitted(true);
          if (isInputValid) {
            await handleSendBtn();
          } else {
            setIsTitleValid(checkTitleValid());
            setIsSubjectValid(checkSubjectValid());
            setIsMessageValid(checkMsgValid());
          }
        }}
      >
        <Stack sx={{ width: "100%" }}>
          <HeaderCourseDocu course={course}></HeaderCourseDocu>
          <SelectTextfield
            error={isSubmitted && !isTitleValid}
            values={["Nachricht", "Absage"]}
            label={"Anliegen auswählen"}
            onChange={(value: string) => {
              setTitle(value);
              setIsTitleValid(value.length !== 0);
            }}
          />
          <TextField
            required
            error={isSubmitted && !isSubjectValid}
            sx={{ width: "100%", marginTop: "20px" }}
            placeholder={"Betreff"}
            variant="outlined"
            value={subject}
            onChange={(e) => {
              const newValue = e.target.value;
              setSubject(newValue);
              setIsSubjectValid(newValue.length !== 0);
            }}
            inputProps={{ maxLength: MAX_CHARS_SUBJECT }}
          />

          <TextFieldWithChars
            placeholder={
              "Schreibe eine Nachricht an alle Teilnehmenden des Kurses"
            }
            minChars={MIN_CHARS_TEXTFIELD}
            maxChars={MAX_CHARS_TEXTFIELD}
            rows={11}
            onChange={(text: string) => {
              setMessage(text);
              setIsMessageValid(text.length >= MIN_CHARS_TEXTFIELD);
            }}
            error={isSubmitted && !isMessageValid}
          />
        </Stack>
      </AdminInfoDialog>
    </>
  );
};
export default AdminNotifyAttendeesDialog;
