import { Trainer, TrainerInterface } from "../models/trainer";
import { getTrainerAxiosInstance } from "./api-config";

export async function getTrainerEntity(trainerUUID: string): Promise<{
  status: number;
  trainer: Trainer;
}> {
  if (trainerUUID === "" || !trainerUUID) {
    return;
  }
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/trainers/${trainerUUID}`);
  const trainerData = response.data;

  return {
    status: response.status,
    trainer: new Trainer(
      trainerData.uuid,
      trainerData.firstName,
      trainerData.lastName,
      trainerData.email,
      trainerData.phone,
      trainerData.isAdmin,
      trainerData.isActivated,
      trainerData.isDeactivated
    ),
  };
}

export async function getAdminManagementTrainerEntities(
  searchQuery: string,
  offset: number
): Promise<{
  status: number;
  trainers: TrainerInterface[];
}> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(
    `/trainers/all-trainers-management?searchQuery=${searchQuery}&offset=${offset}`
  );
  const trainers = response.data;
  const returnData: TrainerInterface[] = [];

  const trainerData: Trainer[] = trainers;

  trainerData.forEach((trainer, idx) => {
    if (trainer) {
      returnData.push({
        ID: idx + offset,
        uuid: trainer.uuid,
        firstName: trainer.firstName,
        lastName: trainer.lastName,
        email: trainer.email,
        phone: trainer.phone,
        isAdmin: trainer.isAdmin,
        isActivated: trainer.isActivated,
        isDeactivated: trainer.isDeactivated,
      });
    }
  });

  return {
    status: response.status,
    trainers: returnData,
  };
}
