export const STANDORTE_TAB = 0;
export const MITGLIEDER_TAB = 1;
export const WARTELISTE_TAB = 2;
export const TRAINER_TAB = 3;

export type AdminManagementTabTypes =
  | typeof STANDORTE_TAB
  | typeof MITGLIEDER_TAB
  | typeof WARTELISTE_TAB
  | typeof TRAINER_TAB;
