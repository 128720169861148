import { Box, Dialog, List, Typography } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import grayX from "../../img/grayX.svg";
import calendar from "../../img/calendar.svg";
import archiv from "../../img/archiv.svg";
import logout from "../../img/logout.svg";
import dashbord from "../../img/dashbord.svg";
import location from "../../img/location.svg";
import trainer from "../../img/trainer.svg";
import waitingList from "../../img/waitingList.svg";
import members from "../../img/members.svg";
import smartphoneHand from "../../img/smartphoneHand.svg";
import { getTrainerEntity } from "../../api/trainer-api";
import { allRoutes } from "../../enums/all-routes";
import { useHistory } from "react-router-dom";
import AdminHamburgerMenuItem from "./AdminHamburgerMenuItem";
import { HttpStatusCode } from "axios";
import { SnackBarContext } from "../../context/snackbar-context";
import { formatSnackBarErrMsg } from "../../util/SnackBarErrorMsgFormatter";
import {
  AdminManagementTabTypes,
  MITGLIEDER_TAB,
  STANDORTE_TAB,
  WARTELISTE_TAB,
  TRAINER_TAB,
} from "../../constants/admin-management-constants";
import { localStorageKeys } from "../../constants/storage-keys";

import {
  setLocalStorageCourseListActive,
  setLocalStorageManagementActiveTab,
} from "../../util/StorageUtil";
import { logoutUser } from "../../api/auth-api";
import {
  redirectToAuth,
  redirectToTrainerApp,
} from "../../util/NavigationUtil";

const AdminHamburgerMenuDialog: React.FC<{
  isOpen: boolean;
  onCloseDialog: () => void;
  setIsCourseListActive: (isCourseListActive: boolean) => void;
  setAdminManagementActiveTab?: (activeTab: AdminManagementTabTypes) => void;
}> = ({
  isOpen,
  onCloseDialog,
  setIsCourseListActive,
  setAdminManagementActiveTab,
}) => {
  const snackBarCtx = useContext(SnackBarContext);
  const [trainersName, setTrainersName] = useState<string>("");

  const trainerUUID: string = localStorage.getItem(
    localStorageKeys.trainerUUID
  );
  const history = useHistory();
  const dialogRef = useRef<HTMLDivElement>(null);

  //to remove the aria-hidden warning
  useEffect(() => {
    if (!isOpen && dialogRef.current) {
      dialogRef.current.setAttribute("inert", "true");
    } else if (isOpen && dialogRef.current) {
      dialogRef.current.removeAttribute("inert");
    }
  }, [isOpen]);

  /* eslint-disable */
  useEffect(() => {
    getTrainerEntity(trainerUUID).then((data) => {
      if (!data) {
        snackBarCtx.openSnackbar(
          formatSnackBarErrMsg(
            data.status,
            `Beim laden des Trainers ist ein Fehler aufgetreten. Bitte lade die Seite neu...`
          )
        );
        return;
      }
      if (data.status === HttpStatusCode.Ok) {
        setTrainersName(`${data.trainer.firstName} ${data.trainer.lastName}`);
      } else {
        snackBarCtx.openSnackbar(
          formatSnackBarErrMsg(
            data.status,
            `Beim laden des Trainers ist ein Fehler aufgetreten. Bitte lade die Seite neu...`
          )
        );
      }
    });
  }, [trainerUUID]);
  /* eslint-enable */

  const titleTypographyStyles = {
    color: "#607783",
    fontFamily: "Tahoma",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
    paddingLeft: "35px",
  };

  const navigateAdminManagement = (activeTab: AdminManagementTabTypes) => {
    setLocalStorageManagementActiveTab(activeTab);
    setAdminManagementActiveTab(activeTab);
    history.push(allRoutes.adminManagement);
    onCloseDialog();
  };

  const navigateAdminTrainerApp = (isCourseListActive: boolean) => {
    setLocalStorageCourseListActive(isCourseListActive);
    setIsCourseListActive(isCourseListActive);
    history.push(allRoutes.adminTrainerApp);
    onCloseDialog();
  };

  return (
    <Dialog
      open={isOpen}
      aria-hidden={!isOpen}
      onClose={onCloseDialog}
      keepMounted
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          height: "650px",
          borderRadius: "8px",
          border: "1px solid rgba(96, 119, 131, 0.50)",
          boxShadow: "0px 20px 20px 0px rgba(0, 0, 0, 0.25)",
          position: "absolute",
          top: "0.5%",
          right: "0.5%",
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          justifyContent: "start",
          padding: "30px",
        }}
      >
        <Box
          onClick={onCloseDialog}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
        >
          <img alt="close" src={grayX} />
        </Box>
        <Typography
          sx={{
            color: "#607783",
            fontFamily: "Tahoma",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "900",
            lineHeight: "normal",
            paddingTop: "20px",
            paddingLeft: "10px",
            paddingBottom: "30px",
          }}
        >
          {trainersName}
        </Typography>
        <Typography sx={{ ...titleTypographyStyles }}> Auswertung </Typography>
        <List>
          <AdminHamburgerMenuItem
            iconSrc={dashbord}
            iconAlt="dashbord"
            text="Dashboard"
            onClick={() => {
              history.push(allRoutes.adminDashboard);
            }}
          />
        </List>
        <Typography sx={{ ...titleTypographyStyles }}>Admin Portal</Typography>
        <List>
          <AdminHamburgerMenuItem
            iconSrc={calendar}
            iconAlt="calendar"
            text="Kursliste"
            onClick={() => {
              navigateAdminTrainerApp(true);
            }}
          />
          <AdminHamburgerMenuItem
            iconSrc={archiv}
            iconAlt="archiv"
            text="Kursarchiv"
            onClick={() => {
              navigateAdminTrainerApp(false);
            }}
          />
        </List>
        <Typography sx={{ ...titleTypographyStyles }}> Verwaltung </Typography>
        <List>
          <AdminHamburgerMenuItem
            iconSrc={members}
            iconAlt="members"
            text="Mitglieder"
            onClick={() => {
              navigateAdminManagement(MITGLIEDER_TAB);
            }}
          />
          <AdminHamburgerMenuItem
            iconSrc={location}
            iconAlt="location"
            text="Standorte"
            onClick={() => {
              navigateAdminManagement(STANDORTE_TAB);
            }}
          />
          <AdminHamburgerMenuItem
            iconSrc={waitingList}
            iconAlt="waitingList"
            text="Warteliste"
            onClick={() => {
              navigateAdminManagement(WARTELISTE_TAB);
            }}
          />
          <AdminHamburgerMenuItem
            iconSrc={trainer}
            iconAlt="trainerIcon"
            text="Trainer"
            onClick={() => {
              navigateAdminManagement(TRAINER_TAB);
            }}
          />
        </List>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          <Typography sx={{ ...titleTypographyStyles }}>Profil</Typography>
          <List>
            <AdminHamburgerMenuItem
              iconSrc={smartphoneHand}
              iconAlt="trainerApp"
              text="TrainerApp"
              onClick={() => {
                redirectToTrainerApp();
              }}
            />
            <AdminHamburgerMenuItem
              iconSrc={logout}
              iconAlt="logout"
              text="Abmelden"
              onClick={async () => {
                await logoutUser();
                redirectToAuth();
              }}
            />
          </List>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AdminHamburgerMenuDialog;
