import { useRef, useState } from "react";

import {
  Box,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import contextMenu from "../../img/contextMenu.svg";

import trash from "../../img/trash.svg";
import edit from "../../img/editPenSVG.svg";
import { AdminManagementDialogKey } from "../../models/admin-management-entities";
import { ContextMenuItem } from "./AdminManagementList";

const AdminManagementListItem: React.FC<{
  dialogKey: AdminManagementDialogKey;
  onClickFct: (id: number) => void;
  data: any;
  columns: {
    attrName: string;
    key?: number;
    flex?: number;
    processFnct?: (_: any) => string;
  }[];
  showBelegungenContextMenu?: boolean;
  onClickChangeBelegung?: (ID_Belegung: number, Kursname: string) => void;
  onClickDeleteBelegung?: (ID_Belegung: number) => void;
  setIsDeleteDialogOpen?: (_: boolean) => void;
  setIsEntityDialogOpen?: (_: boolean) => void;
  contextMenuItems?: ContextMenuItem[];
}> = ({
  dialogKey,
  data,
  columns,
  onClickFct,
  showBelegungenContextMenu,
  onClickChangeBelegung,
  onClickDeleteBelegung,
  setIsDeleteDialogOpen,
  setIsEntityDialogOpen,
  contextMenuItems,
}) => {
  const contextItemTextStyle = {
    color: "#607783",
    fontFamily: "Tahoma",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
  };
  const listItemColumnsWithDefaultValues = columns.map(
    ({ key, attrName, flex, processFnct }, idx) => {
      key = idx;
      if (!flex) {
        flex = 1;
      }
      if (!processFnct) {
        processFnct = (_: string) => {
          return _;
        };
      }
      return { key, attrName, flex, processFnct };
    }
  );

  /* Context menu */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  const boxRef = useRef<HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClickFct(data.ID);
    if (boxRef.current) {
      const box = boxRef.current.getBoundingClientRect();
      setMenuPosition({
        left: box.left - box.width,
        top: box.top - box.height,
      });
      setAnchorEl(boxRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defaultContextMenuItems = [
    {
      text: onClickDeleteBelegung ? "Sportgruppe wechseln" : "Ändern",
      icon: edit,
      onClick: () => {
        onClickChangeBelegung
          ? onClickChangeBelegung(data.ID_Belegung, data.Kursname)
          : setIsEntityDialogOpen && setIsEntityDialogOpen(true);
        handleClose();
      },
    },
    {
      text: onClickDeleteBelegung ? "Belegung beenden" : "Löschen",
      icon: trash,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        if (onClickDeleteBelegung) {
          onClickDeleteBelegung(data.ID_Belegung);
        } else {
          onClickFct(data.ID);
          setIsDeleteDialogOpen && setIsDeleteDialogOpen(true);
        }
        handleClose();
        e.stopPropagation();
      },
    },
  ];

  const menuItems = (contextMenuItems || defaultContextMenuItems).map(
    (item) => ({
      ...item,
      onClick: (e: React.MouseEvent<HTMLElement>) => {
        item.onClick(e);
        handleClose();
        e.stopPropagation();
      },
    })
  );

  return (
    <Box
      sx={{
        padding: "0",
        margin: "0",
      }}
    >
      <ListItem
        onClick={() => {
          onClickFct(data.ID);
          anchorEl === null &&
            setIsEntityDialogOpen &&
            setIsEntityDialogOpen(true);
        }}
        sx={{
          padding: "0",
          margin: "5px 0 5px 0",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: "var(--borderRadiusLarge)",
          border: `1px solid var(--orange)`,
          height: "50px",
          cursor: "pointer",
        }}
      >
        {listItemColumnsWithDefaultValues.map(
          ({ key, attrName, flex, processFnct }) => (
            <Typography
              noWrap
              key={key}
              sx={{
                flex: flex,
                color: "var(--grey)",
                fontWeight: "bold",
                fontSize: "var(--h4)",
                marginLeft: key === 0 ? "50px" : "0",
                overflow: "hidden",
              }}
            >
              {processFnct(data[attrName])}
            </Typography>
          )
        )}
        {showBelegungenContextMenu &&
          dialogKey !== AdminManagementDialogKey.WARTELISTE && (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                handleClick(e);
              }}
              flex={1}
              sx={{
                minWidth: "40px",
                display: "flex",
                justifyContent: "end",
              }}
            >
              {!(
                dialogKey === AdminManagementDialogKey.BELEGUNG &&
                data.Mitgliedschaftsende !== null
              ) && (
                <Box ref={boxRef}>
                  <img
                    src={contextMenu}
                    alt="courseIcon"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "20px",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        {/* Context menu */}
        <Menu
          id="basic-menu"
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{ top: menuPosition.top, left: menuPosition.left }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            style: {
              left: `${menuPosition.left}px`,
              top: `${menuPosition.top}px`,
              position: "absolute",
              border: "1.5px solid rgba(96, 119, 131, 0.5)",
              boxShadow:
                "0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)",
              width: "200px",
            },
          }}
        >
          {menuItems.map((item, index) => (
            <div key={index}>
              <MenuItem sx={{ py: 0.1 }} onClick={item.onClick}>
                <ListItemIcon style={{ minWidth: "25px" }}>
                  <img
                    src={item.icon}
                    alt={item.text}
                    style={{ height: "16px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ ...contextItemTextStyle }}
                >
                  {item.text}
                </ListItemText>
              </MenuItem>
              {index < menuItems.length - 1 && <Divider />}
            </div>
          ))}
        </Menu>
      </ListItem>
    </Box>
  );
};

export default AdminManagementListItem;
