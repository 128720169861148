import { Box, Paper, Typography } from "@mui/material";
import { Course } from "../../../models/course";
import { getDate, getTimeFromDates } from "../../../util/DateFormatHelper";
import "../../../css/globalVariables.css";
import { localStringToDate } from "../../../util/DateFormatHelper";
import { Trainer } from "../../../models/trainer";

const HeaderCourseDocu: React.FC<{
  course: Course;
  isArchive?: boolean;
  subtitle?: string;
  showTopStepper?: boolean;
}> = ({ course, isArchive, subtitle, showTopStepper }) => {
  const isDateToday = () => {
    if (!course.startDate) {
      return;
    }
    const today = new Date();
    return (
      localStringToDate(course.startDate).toDateString() ===
      today.toDateString()
    );
  };

  const getCourseTrainersJSX = () => {
    const getTrainerString = (trainer: Trainer) => {
      return `${trainer.firstName} ${trainer.lastName}`;
    };
    if (!course.trainers || !course.documentingTrainerUUID) {
      return;
    }
    const docuTrainerUUID = course.documentingTrainerUUID;
    const docuTrainer = course.trainers.find(
      (trainer) => trainer.uuid === docuTrainerUUID
    );

    const allOtherTrainers = course.trainers.filter(
      (trainer) => trainer.uuid !== docuTrainerUUID
    );
    if (allOtherTrainers.length === 0) {
      return (
        <Typography
          textAlign={"right"}
          sx={{
            fontSize: "var(--h4)",
            color: "var(--orange)",
          }}
        >
          {getTrainerString(docuTrainer)}
        </Typography>
      );
    } else {
      const docuTrainerJSX = (
        <span
          style={{
            fontSize: "var(--h4)",
            color: "var(--orange)",
          }}
        >
          {getTrainerString(docuTrainer)}
        </span>
      );
      let returnString = ` | `;
      let i = 0;
      let endIndex = allOtherTrainers.length - 1;
      while (true) {
        returnString = returnString + getTrainerString(allOtherTrainers[i]);
        if (i === endIndex) {
          break;
        }
        returnString = returnString + " | ";
        i = i + 1;
      }
      return (
        <Typography
          textAlign={"right"}
          sx={{
            fontSize: "var(--h4)",
            color: "var(--grey)",
            whiteSpace: "nowrap",
          }}
        >
          {docuTrainerJSX}
          {returnString}
        </Typography>
      );
    }
  };

  return (
    <>
      <Paper
        sx={{
          boxShadow: "none",
          //   padding: "10px 35px 10px 35px",
          borderRadius: "0",
          zIndex: "1000",
        }}
        elevation={3}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "75%" }}>
            <Typography
              sx={{
                margin: "20px 20px 0px 0px",
                fontWeight: "bold",
                fontSize: "var(--h0)",
                color: "var(--grey)",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {course.courseName}
            </Typography>
            {showTopStepper && (
              <Typography
                sx={{
                  fontSize: "var(--h4)",
                  color: "var(--grey)",
                }}
              >
                {subtitle}
              </Typography>
            )}
          </Box>
          <Box marginTop={"20px"}>
            <Typography
              textAlign={"right"}
              sx={{
                fontSize: "var(--h4)",
                fontWeight: "bold",
                color: "var(--grey)",
              }}
            >
              {isDateToday() ? "Heute" : getDate(course.startDate)}
            </Typography>
            <Typography
              textAlign={"right"}
              sx={{
                fontSize: "var(--h4)",
                color: "var(--grey)",
              }}
            >
              {getTimeFromDates(course.startDate, course.endDate)}
            </Typography>
            {isArchive && getCourseTrainersJSX()}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default HeaderCourseDocu;
