import { Box, Typography } from "@mui/material";
import classes from "../../css/Switcher.module.css";
import location from "../../img/location.svg";
import locationActive from "../../img/locationActive.svg";
import members from "../../img/members.svg";
import waitingList from "../../img/waitingList.svg";
import waitingListActive from "../../img/waitingListActive.svg";
import membersActive from "../../img/membersActive.svg";
import trainer from "../../img/trainer.svg";
import trainerActive from "../../img/trainerActive.svg";

import {
  AdminManagementTabTypes,
  MITGLIEDER_TAB,
  STANDORTE_TAB,
  WARTELISTE_TAB,
  TRAINER_TAB,
} from "../../constants/admin-management-constants";

const AdminManagementSwitcher: React.FC<{
  activeTab: AdminManagementTabTypes;
  onChangeTab: (newTab: AdminManagementTabTypes) => void;
}> = ({ activeTab, onChangeTab }) => {
  const isStandorteTab = activeTab === STANDORTE_TAB;
  const isMitgliederTab = activeTab === MITGLIEDER_TAB;
  const isWartelisteTab = activeTab === WARTELISTE_TAB;
  const isTrainerTab = activeTab === TRAINER_TAB;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
        margin: "auto",
      }}
    >
      <button
        style={{ cursor: "pointer" }}
        className={`${classes.listBtn} ${
          isMitgliederTab ? classes.btnActive : classes.btnNotActive
        } `}
        onClick={() => onChangeTab(MITGLIEDER_TAB)}
      >
        <img
          alt="Mitglieder Img"
          src={isMitgliederTab ? membersActive : members}
          className={classes.img}
        />
        <Typography
          sx={{
            color: isMitgliederTab ? "white" : "var(--grey)",
            fontWeight: "bold",
            fontSize: "var(--h2)",
          }}
        >
          Mitglieder
        </Typography>
      </button>
      <button
        style={{ cursor: "pointer" }}
        className={`${classes.middleBtn} ${
          isStandorteTab ? classes.btnActive : classes.btnNotActive
        } `}
        onClick={() => onChangeTab(STANDORTE_TAB)}
      >
        <img
          alt="Standorte Img"
          src={isStandorteTab ? locationActive : location}
          className={classes.img}
          style={{
            color: "white",
          }}
        />
        <Typography
          sx={{
            color: isStandorteTab ? "white" : "var(--grey)",
            fontWeight: "bold",
            fontSize: "var(--h2)",
          }}
        >
          Standorte
        </Typography>
      </button>

      <button
        style={{ cursor: "pointer" }}
        className={`${classes.middleBtn} ${
          isWartelisteTab ? classes.btnActive : classes.btnNotActive
        } `}
        onClick={() => onChangeTab(WARTELISTE_TAB)}
      >
        <img
          alt="Warteliste Img"
          src={isWartelisteTab ? waitingListActive : waitingList}
          className={classes.img}
        />
        <Typography
          sx={{
            color: isWartelisteTab ? "white" : "var(--grey)",
            fontWeight: "bold",
            fontSize: "var(--h2)",
          }}
        >
          Warteliste
        </Typography>
      </button>
      <button
        style={{ cursor: "pointer" }}
        className={`${classes.archiveBtn} ${
          isTrainerTab ? classes.btnActive : classes.btnNotActive
        } `}
        onClick={() => onChangeTab(TRAINER_TAB)}
      >
        <img
          alt="Trainer Img"
          src={isTrainerTab ? trainerActive : trainer}
          className={classes.img}
        />
        <Typography
          sx={{
            color: isTrainerTab ? "white" : "var(--grey)",
            fontWeight: "bold",
            fontSize: "var(--h2)",
          }}
        >
          Trainer
        </Typography>
      </button>
    </Box>
  );
};

export default AdminManagementSwitcher;
