import { Trainer } from "../models/trainer";
import { Course, CourseStates } from "../models/course";
import { groupAndSortCourses } from "../util/CourseUtilFunctions";
import { getTrainerAxiosInstance, getSyncAxiosInstance } from "./api-config";
import { Attachment } from "../models/attachment";
import { Incident } from "../models/incident";
import { HttpStatusCode } from "axios";

export type CourseFilters = {
  courseNames?: string[];
  locations?: string[];
  startDate?: string;
  endDate?: string;
  trainerUUIDs?: string[];
  hallConditionValue?: boolean;
  materialConditionValue?: boolean;
  incidentsExist?: boolean;
  messageExists?: boolean;
  states?: string[];
  searchQuery?: string;
};

export async function getParticipantsByCourseName(courseName: string) {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(
    `/participants?courseName=${courseName}`
  );
  if (response.status === HttpStatusCode.Ok) {
    const participantData = response.data.participants;
    return participantData;
  }
}

export async function updateCourse(
  courseID: string,
  courseUpdateAttr: Record<string, any>
): Promise<{
  status: number;
  courseID?: string;
  error?: string;
}> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.put(`/courses/${courseID}`, {
    updateAttr: courseUpdateAttr,
  });
  return response.data;
}

export async function deleteCourse(id: string) {
  const axiosInstance = getTrainerAxiosInstance();
  return await axiosInstance.delete(`/courses/admin/${id}`);
}

const processCourseAttachments = (courseList: Course[]) => {
  return courseList.map((course: Course) => {
    let hallAttachments: Attachment[] = [];
    let materialAttachments: Attachment[] = [];
    if (course.hallConditionAttachments) {
      hallAttachments = course.hallConditionAttachments.map(
        (a: Attachment) => new Attachment(a.link)
      );
    }
    if (course.materialConditionAttachments) {
      materialAttachments = course.materialConditionAttachments.map(
        (a: Attachment) => new Attachment(a.link)
      );
    }
    let updatedIncidents: Incident[] = [];
    if (course.incidents) {
      updatedIncidents = course.incidents.map((incident: Incident) => {
        let updatedAttachments: Attachment[] = [];
        if (incident.attachments) {
          updatedAttachments = incident.attachments.map(
            (a: Attachment) => new Attachment(a.link)
          );
        }
        return new Incident(
          incident.incidentUUID,
          incident.incidentType,
          incident.courseID,
          incident.description,
          updatedAttachments,
          incident.isSaved
        );
      });
    }
    return {
      ...course,
      hallConditionAttachments: hallAttachments,
      materialConditionAttachments: materialAttachments,
      incidents: updatedIncidents,
    };
  });
};

export async function getAdminFilterOptions() {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get("/courses/admin/filter-options");
  return response.data;
}

export async function getAllCourseNames() {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get("/courses/course-names");
  const allCourseNames: string[] = response.data;
  return allCourseNames;
}

export async function getAllCourseLocations() {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get("/courses/course-locations");
  const allCourseLocations: string[] = response.data;
  return allCourseLocations;
}

export async function getAllTrainers() {
  const axiosInstance = getTrainerAxiosInstance();
  const allTrainers: Trainer[] = await axiosInstance.get(
    "trainers/all-trainers"
  );
  return allTrainers;
}

export async function syncSmartplanData(): Promise<void> {
  const axiosInstance = getSyncAxiosInstance();
  await axiosInstance.post(`/sync`);
}

export async function getCounter() {
  const axiosInstance = getSyncAxiosInstance();

  const response = await axiosInstance.get(`/sync/counter`);
  return response.data;
}

export async function getAdminArchiveCourses(offset: number): Promise<{
  status: number;
  courses: Record<string, Course[]>;
}> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(`/courses/admin/archive`, {
    params: { offset },
  });
  const adminArchiveCourses: Course[] = response.data;
  const processedCourses: Course[] =
    processCourseAttachments(adminArchiveCourses);

  const groupedCourses: Record<string, Course[]> = groupAndSortCourses(
    processedCourses,
    false
  );
  return { status: response.status, courses: groupedCourses };
}

export async function getAdminCourses(
  offset: number
): Promise<Record<string, Course[]>> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(
    `/courses/admin/courses?offset=${offset}`
  );
  const adminCourses: Course[] = response.data;
  const groupedCourses: Record<string, Course[]> =
    groupAndSortCourses(adminCourses);
  return groupedCourses;
}

export async function cancelCourse(courseID: string, message: string) {
  await updateCourse(courseID, {
    state: CourseStates.CANCELED,
    message: message,
  });
}

export async function filterCourses(
  courseFilters: CourseFilters,
  isArchive: boolean,
  offset: number
): Promise<Record<string, Course[]>> {
  const axiosInstance = getTrainerAxiosInstance();
  const response = await axiosInstance.get(
    `/courses/admin/filter-courses?offset=${offset}&isArchive=${isArchive}`,
    {
      params: courseFilters,
    }
  );
  const filteredCourses: Course[] = response.data;
  const processedCourses: Course[] = processCourseAttachments(filteredCourses);
  const groupedFilteredCourses: Record<string, Course[]> =
    groupAndSortCourses(processedCourses);
  return groupedFilteredCourses;
}
