import {
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useRef, useState } from "react";
import "../../../css/globalVariables.css";
import { Course, CourseStates } from "../../../models/course";
import trash from "../../../img/trash.svg";
import mail from "../../../img/mail.svg";
import grayX from "../../../img/grayX.svg";
import contextMenu from "../../../img/contextMenu.svg";
import contextMenuFill from "../../../img/contextMenuFill.svg";
import redEditPen from "../../../img/redEditPen.svg";
import yellowClock from "../../../img/yellowClock.svg";
import editPenSVG from "../../../img/editPenSVG.svg";
import trashcan from "../../../img/trash.svg";
import { getTimeFromDates } from "../../../util/DateFormatHelper";
import AdminCancelCourseDialog from "../Dialogs/AdminCancelCourseDialog";
import AdminInfoDialog from "../Dialogs/AdminInfoDialog";
import { HttpStatusCode } from "axios";
import { SnackBarContext } from "../../../context/snackbar-context";
import { getDeleteDialogTextContent } from "../../../util/CourseComponentUtilFunctions";
import { adminDeleteCourse } from "../../../util/CourseUtilFunctions";
import { updateCourse } from "../../../api/admin-trainer-app-api";
import AdminNotifyAttendeesDialog from "../Dialogs/AdminNotifiyAttendeesDialog";

const AdminCourseListItem: React.FC<{
  course: Course;
  reloadCourses: () => Promise<void>;
  handleDeleteCourse: (idOfCourse: string) => void;
}> = ({ course, reloadCourses, handleDeleteCourse }) => {
  const snackBarCtx = useContext(SnackBarContext);

  const [isCancelCourseDialogOpen, setIsCancelCourseDialogOpen] =
    useState(false);

  const [isReEditDialogOpen, setIsReEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState(false);
  const isCourseReadOnly =
    course.state === CourseStates.READ_ONLY && !course.isLateDocu;

  /* Context menu */
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  const boxRef = useRef<HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    document.body.style.overflow = "scroll";

    if (boxRef.current) {
      const box = boxRef.current.getBoundingClientRect();
      setMenuPosition({
        left: box.right,
        top: box.bottom,
      });
      setIsContextMenuOpen(true);
      setAnchorEl(boxRef.current);
    }
  };

  const handleClose = () => {
    setIsContextMenuOpen(false);
    getCourseIconComponent();
    setAnchorEl(null);
  };

  const getTrainerString = () => {
    if (course.trainers) {
      const trainerNames = course.trainers.map(
        (trainer) => `${trainer.firstName} ${trainer.lastName}`
      );
      return trainerNames.join(" | ");
    }
  };

  const getCourseColor = (state: string) => {
    switch (state) {
      case CourseStates.READ_ONLY:
        return "var(--red)";
      case CourseStates.READY_FOR_CHECK_IN || CourseStates.IN_EDIT:
        return "var(--orange)";
      case CourseStates.UPCOMING:
        return "var(--grey)";

      default:
        return "var(--orange)";
    }
  };

  const getCourseIconComponent = () => {
    const courseIconImgComponent = (iconURL: string) => {
      return (
        <img
          src={iconURL}
          alt="courseIcon"
          style={{
            height: "20px",
            width: "20px",
            margin: "auto",
            marginLeft: "10px",
          }}
        ></img>
      );
    };
    const emptyBox = (
      <Box
        height={"20px"}
        width={"20px"}
        margin={"auto"}
        marginLeft={"10px"}
      ></Box>
    );

    if (isCourseReadOnly) {
      return courseIconImgComponent(redEditPen);
    }
    if (course.state === CourseStates.IN_EDIT) {
      return courseIconImgComponent(yellowClock);
    }
    if (course.state === CourseStates.UPCOMING) {
      return courseIconImgComponent(
        isContextMenuOpen ? contextMenuFill : contextMenu
      );
    }
    return emptyBox;
  };

  const getInfoDialogTextContent = (courseName: string) => {
    return (
      <>
        Möchtest du die Dokumentation des Kurs <b>{courseName}</b> wirklich
        freigeben ?
      </>
    );
  };

  const typographyStyle = {
    flex: 1,
    fontSize: "var(--h4)",
    color: "var(--grey)",
    overflow: "hidden",
  };

  const itemTypographyStyles = {
    color: "#607783",
    fontFamily: "Tahoma",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "normal",
  };

  return (
    <>
      <ListItem
        onClick={(e) => {
          if (course.state === CourseStates.UPCOMING) {
            handleClick(e);
          }
        }}
        sx={{
          padding: "0",
          margin: "0",
        }}
      >
        <ListItemButton
          sx={{
            padding: "0",
            margin: "5px 0 5px 0",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: "var(--borderRadiusLarge)",
            border: `1px solid ${getCourseColor(course.state)}`,
          }}
        >
          <Typography
            flex={1}
            noWrap
            sx={{
              fontFamily: "Tahoma",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "18px",
              letterSpacing: "-0.01px",
              padding: "10px",
              marginLeft: "30px",
              justifyContent: "flex-start",
              overflow: "hidden",
              color: getCourseColor(course.state),
            }}
          >
            {course.courseName}
          </Typography>
          <Typography noWrap sx={typographyStyle}>
            {course.location}
          </Typography>
          <Typography sx={typographyStyle}>{getTrainerString()}</Typography>
          <Typography sx={typographyStyle}>
            {getTimeFromDates(course.startDate, course.endDate)}
          </Typography>
          <Box
            ref={boxRef}
            onClick={(e) => {
              e.stopPropagation();
              if (isCourseReadOnly) {
                setIsReEditDialogOpen(true);
              }
              if (course.state === CourseStates.UPCOMING) {
                handleClick(e);
              }
            }}
            sx={{ margin: "10px" }}
          >
            {getCourseIconComponent()}
          </Box>
        </ListItemButton>
      </ListItem>
      <AdminCancelCourseDialog
        course={course}
        onCloseDialog={() => {
          setIsCancelCourseDialogOpen(false);
        }}
        showDialog={isCancelCourseDialogOpen}
      ></AdminCancelCourseDialog>
      {isReEditDialogOpen && (
        <AdminInfoDialog
          isOpen={isReEditDialogOpen}
          onCloseDialog={() => {
            setIsReEditDialogOpen(false);
          }}
          iconURL={editPenSVG}
          textChildren={getInfoDialogTextContent(course.courseName)}
          rightBtnLabel="Bestätigen"
          rightBtnOnClick={async () => {
            const response = await updateCourse(course.courseID, {
              isLateDocu: true,
              documentingTrainerUUID: "",
              state: CourseStates.READY_FOR_CHECK_IN,
            });
            if (response.status === HttpStatusCode.Ok) {
              setIsReEditDialogOpen(false);
              await reloadCourses();
            } else {
              snackBarCtx.openSnackbar(
                `${response.status} | Ein Fehler ist aufgetreten bitte versuche es erneut...`
              );
            }
          }}
          leftBtnLabel="Abbrechen"
          leftBtnOnClick={() => {
            setIsReEditDialogOpen(false);
          }}
        ></AdminInfoDialog>
      )}
      {isDeleteDialogOpen && (
        <AdminInfoDialog
          isOpen={isDeleteDialogOpen}
          onCloseDialog={() => {
            setIsDeleteDialogOpen(false);
          }}
          iconURL={trashcan}
          textChildren={getDeleteDialogTextContent(course)}
          rightBtnLabel="Bestätigen"
          rightBtnOnClick={async () => {
            await adminDeleteCourse(
              course.id,
              setIsDeleteDialogOpen,
              handleDeleteCourse,
              snackBarCtx
            );
          }}
          leftBtnLabel="Abbrechen"
          leftBtnOnClick={() => {
            setIsDeleteDialogOpen(false);
          }}
        ></AdminInfoDialog>
      )}
      {isNotifyDialogOpen && (
        <AdminNotifyAttendeesDialog
          isOpen={isNotifyDialogOpen}
          onCloseDialog={() => {
            setIsNotifyDialogOpen(false);
          }}
          course={course}
        ></AdminNotifyAttendeesDialog>
      )}
      {/*Context menu*/}
      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: menuPosition.top, left: menuPosition.left }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            left: `${menuPosition.left}px`,
            top: `${menuPosition.top}px`,
            position: "absolute",
            border: "1.5px solid rgba(96, 119, 131, 0.5)",
            boxShadow:
              "0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)",
          },
        }}
      >
        <MenuItem
          sx={{ py: 0.1 }}
          onClick={() => {
            handleClose();
            setIsCancelCourseDialogOpen(true);
          }}
        >
          <ListItemIcon style={{ minWidth: "25px" }}>
            <img src={grayX} alt="grayX" style={{ width: "11px" }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ ...itemTypographyStyles }}>
            Kurs absagen
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ py: 0.1 }}
          onClick={() => {
            handleClose();
            setIsDeleteDialogOpen(true);
          }}
        >
          <ListItemIcon style={{ minWidth: "25px" }}>
            <img src={trash} alt="trash" style={{ width: "12px" }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ ...itemTypographyStyles }}>
            Kurs löschen
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ py: 0.1 }}
          onClick={() => {
            handleClose();
            setIsNotifyDialogOpen(true);
          }}
        >
          <ListItemIcon style={{ minWidth: "25px" }}>
            <img src={mail} alt="mail" style={{ width: "12px" }} />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ ...itemTypographyStyles }}>
            Teilnehmende benachrichtigen
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
export default AdminCourseListItem;
